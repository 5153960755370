export async function fetchData() {
    // GET request using fetch with set headers
    const headers = { 'Content-Type': 'application/json' };
    const response = await fetch('https://power-api.s-reck.de/power/data', { headers });
    //
    // {
    //      "Wind Onshore": {
    //          1704754800000: 14070,
    //          ...
    //      },
    //      ....
    // }
    //
    return await response.json();
}
