import { fetchData } from"./Api.js";
import { transform } from "./Lib.js";
import Spinner from "./Spinner.js";
import Graph from "./Graph.js";
import { useState, useEffect } from "react";

function Page() {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    const getData = async () => {
        // fetch API data from backend in ENTSOE format
        const dataRaw = await fetchData();

        // transform to recharts readable format
        const data = transform(dataRaw);
        setData(data);
        setIsLoading(false);
    }

    useEffect(() => {
        getData();
    }, []);


    if (isLoading) {
        return <Spinner />;
    }

    return <Graph data={data} />;

}

export default Page;
