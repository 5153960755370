import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

function Graph({ data }) {

    return <div className="GraphContainer">
            <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={500}
              height={400}
              data={data}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="method" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="Biomass" stackId="1" stroke="#a6a8d2" fill="#a6a8d2" />
              <Area type="monotone" dataKey="Fossil Brown coal/Lignite" stackId="1" stroke="#c9c9c9" fill="#c9c9c9" />
              <Area type="monotone" dataKey="Fossil Gas" stackId="1" stroke="#00b8aa" fill="#00b8aa" />
              <Area type="monotone" dataKey="Fossil Hard coal" stackId="1" stroke="#bc82b3" fill="#bc82b3" />
              <Area type="monotone" dataKey="Fossil Oil" stackId="1" stroke="#ee7082" fill="#ee7082" />
              <Area type="monotone" dataKey="Geothermal" stackId="1" stroke="#dad087" fill="#dad087" />
              <Area type="monotone" dataKey="Hydro Pumped Storage" stackId="1" stroke="#0090da" fill="#0090da" />
              <Area type="monotone" dataKey="Hydro Run-of-river and poundage" stackId="1" stroke="#00ab5f" fill="#00ab5f" />
              <Area type="monotone" dataKey="Hydro Water Reservoir" stackId="1" stroke="#ff9bcf" fill="#ff9bcf" />
              <Area type="monotone" dataKey="Nuclear" stackId="1" stroke="#c9c9c9" fill="#c9c9c9" />
              <Area type="monotone" dataKey="Other renewable" stackId="1" stroke="#00b8aa" fill="#00b8aa" />
              <Area type="monotone" dataKey="Other" stackId="1" stroke="#75bee3" fill="#75bee3" />
              <Area type="monotone" dataKey="Solar" stackId="1" stroke="#bc82b3" fill="#bc82b3" />
              <Area type="monotone" dataKey="Waste" stackId="1" stroke="#ee7082" fill="#ee7082" />
              <Area type="monotone" dataKey="Wind Offshore" stackId="1" stroke="#9ba841" fill="#9ba841" />
              <Area type="monotone" dataKey="Wind Onshore" stackId="1" stroke="#e9b796" fill="#e9b796" />
            </AreaChart>
          </ResponsiveContainer>
        </div>;
}

export default Graph;
