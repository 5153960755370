export function transform(obj) {

    const arr = [];

    // We want that:
    // {
    //      1704754800000: {
    //          "Wind Onshore": 14070
    //          ....
    //      }
    // }
    //
    const map = new Map();

    for (let methodFullName in obj) {

        if (!methodFullName.includes("Actual Aggregated")) {
            continue;
        }

        const regex = /\('(.*)', '.*'\)/;
        const method = methodFullName.match(regex)[1];
        //console.log(method);

        // console.log(method, obj[method]);
        for (let time in obj[methodFullName]) {
            const value = obj[methodFullName][time];

            if (map.has(time)) {
                map.get(time).set(method, value);
            } else {
                map.set(time, new Map([[method, value]]));
            }
        }
    }

    console.log(map);

    // transform map to array that can get read by the graph js system
    for (const [time, methodsMapWithValues] of map.entries()) {
        const obj = Object.fromEntries(methodsMapWithValues);
        obj["method"] = new Date(parseInt(time)).toLocaleTimeString("de-DE");
        arr.push(obj);
    }

    //console.log(arr);

    return arr;

}
